@import "../../styles/helpers";

.featureSection {
    padding: 80px 0;
    @include s {
        padding: 25px 0; } }
.container {
    position: relative;
    .row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0 -15px; } }

.textContainer {
    h1 {
        font-size: 64px;
        font-weight: 800;
        line-height: 1.1;
        @include t {
            font-size: 54px; }
        @include s {
            font-size: 44px; }
        @include a {
            font-size: 34px; } }
    p {
        font-size: 21px;
        margin-top: 30px;
        margin-bottom: 30px;
        @include t {
            font-size: 18px; } } }
.reverse {
        padding: 0 15px; }


.featureImage {
    img {
        max-height: 100%;
        max-width: 100%; } }

.fadeInUp {
    position: relative;
    transform: translateY(30%);
    opacity: 0;
    transition: ease transform 300ms, ease opacity 200ms;
    &.visible {
        transform: translateY(0);
        opacity: 1; } }

