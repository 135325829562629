[class^="button"] {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding: 0 24px;
  font-size: 16px;
  background: $p1;
  border-radius: 6px;
  min-width: 100px;
  @include button-1;
  text-align: center;
  color: $white;
  transition: all .2s;
  user-select: none;
  cursor: pointer;
  font-weight: 500;
  border-radius: 40px;
  @include m {
    height: 45px; }
  &:hover {
    background: darken($p1, 15); }
  &.disabled {
    opacity: .5;
    pointer-events: none; }
  svg {
    fill: $n8;
    transition: all .2s; }
  @include t {
    padding: 0 20px; } }

.button-stroke {
  background: transparent !important;
  font-weight: 500 !important;
  box-shadow: 0 0 0 2px $p1 inset;
  color: $p1  !important;
  svg {
    fill: $p1; }
  &:hover,
  &.active {
    background: darken($p1,15) !important;
    box-shadow: 0 0 0 2px $p1 inset;
    color: $white !important;
    svg {
      fill: $n8; }
    img {
      filter: invert(1); } }
  &.rectangle {
    border-radius: 10px; }
  &.primary {
    box-shadow: 0 0 0 2px $p2 inset;
    color: $n1;
    svg {
      fill: $p2; }
    &:hover,
    &.active {
      background: $p2;
      box-shadow: 0 0 0 2px $p2 inset;
      color: $n8;
      svg {
        fill: $n8; } } } }




.button-gradient {
  background: linear-gradient(90deg, #273ec2 0%, #81d1dd 100%);
  color: $n8;
  font-weight: 500;
  @include saira;
  &:hover,
  &.active {
    background: linear-gradient(90deg, darken(#81d1dd,5) 0%, darken(#273ec2,5)  100%);
    transition: all .6s; }
  &:disabled {
    filter: grayscale(100%); } }



.button-primary {
  background: $secondary;
  color: $white;
  font-weight: bold;
  transition: all .1s;
  &:hover,
  &.active {
    background:darken($secondary, 10) {} }
  &:disabled {
    opacity: 0.4;
    cursor: default;
    svg {
      fill: $n2; } }
  &.rectangle {
    border-radius: 10px; }
  &.bold {
    font-weight: 700; } }

.button-text {
  background: none;
  color: $p1;
  font-weight: 600;
  height: auto;
  padding: 0;
  min-width: auto;
  &:hover,
  &.active {
    color: darken($p1, 15);
    background: none; }
  &:disabled {
    opacity: 0.4;
    cursor: default;
    svg {
      fill: $n5; } }
  &.rectangle {
    border-radius: 10px; }
  &.bold {
    font-weight: 700; } }


.button-danger {
  background: $danger;
  font-weight: 500;
  transition: all .5s;
  &:hover,
  &.active {
    background: darken($danger, 15); }
  &:disabled {
    background: $n6;
    color: $n2;
    cursor: default;
    svg {
      fill: $n2; } }
  &.rectangle {
    border-radius: 10px; }
  &.bold {
    font-weight: 700; } }


.button-small {
  height: 40px;
  border-radius: 20px;
  padding: 0 16px;
  font-size: 14px; }

.button-circle-stroke {
  flex: 0 0 48px;
  width: 48px;
  height: 48px;
  padding: 0;
  border-radius: 50%;
  box-shadow: 0 0 0 2px $n6 inset;
  background: transparent;
  transition: all .2s;
  svg {
      fill: $n4; }
  &:hover {
      background: $n2;
      box-shadow: 0 0 0 2px $n2 inset;
      svg {
          fill: $n8; } } }


.button-circle-stroke.button-small {
  flex: 0 0 40px;
  width: 40px;
  height: 40px; }


.loader {
    width: 20px;
    height: 20px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s infinite linear; }


@keyframes spin {
  to {
    transform: translateY(0%) rotate(360deg); } }


