@import "../../styles/helpers";

.default {
    background-color: transparent;
    transition: 'background-color 0.3s ease-in-out'; }


.scrolled {
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, box-shadow 0.3s;
    z-index: 1000; }

[class^="button"] {
    &.btnStartFree {
        @include s {
            padding: 0 15px;
            height: 40px;
            font-size: 14px; } } }
