@import "../../styles/helpers";

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    h2 {
        margin-bottom: 50px; } }

.partners {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    text-align: center;
    @include s {
        justify-content: flex-start; }
    .partnersImg {
        width: 20%;
        padding: 0 10px;
        @include m {
            width: 33.33%; }
        @include s {
            width: 50%; }
        img {
            max-width: 180px;
            max-height: 100px;
            @include d {
                max-width: 150px;
                max-height: 80px; }
            @include t {
                max-width: 100px;
                max-height: 50px;
                margin: 10px 0; } } } }


