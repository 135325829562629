@import '../../styles/app.sass';

.pricingSection {
    padding: 100px 0 200px;
    @include t {
        padding: 100px 0 150px; }
    @include m {
        padding: 100px 0 100px; }
    @include a {
        padding: 50px 0; } }
.container {
    .row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0 -15px;
        .col {
            padding: 0 15px;
            width: 33.33%;
            @include m {
                width: 100%; } } } }

.pricingContainer {
    padding: 20px;
    background: $white;
    border-radius: 10px;
    color: $p1;
    text-align: center;
    .pricingHeader {}

    .price {
        font-size: 50px;
        font-weight: 800;
        line-height: 1.1;
        margin-top: 30px;
        margin-bottom: 30px;
        span {
            font-size: 20px;
            font-weight: 400;
            margin-top: 30px;
            margin-bottom: 30px; } }

    .pricingContent {
        color: $black; }
    .pricingFooter {
        margin: 20px 0; } }


.alternate {
    transform: scale(1.2);
    background: $background2;
    border: 2px solid $p1;
    color: $white;
    @include d {
       transform: scale(1.3); }
    @include m {
       transform: scale(1);
       margin: 20px 0; }
    .pricingContent {
        color: $white; } }
