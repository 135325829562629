.fadeinImage {
  position: relative;
  opacity: 0;
  transition: all 1s ease-in-out;
  top: 50px; }


.visible {
  opacity: 1;
  top: 0; }

.hidden {
  opacity: 0;
  top: 50px; }
