@import url('https://fonts.googleapis.com/css2?family=Saira:wght@400;700&display=swap');

// common styles
body {
    background: $background1;
    min-height: 100vh;
    @include saira;
    font-size: 14px;
    line-height: (24/14);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $primaryFont; }


#root {
    color: $primaryFont;
    @include saira; }


.blob {
  position: fixed;
  width: 666px;
  height: 682px;
  left: -175px;
  top: -203px;
  z-index: -1;
  background: rgba(58, 58, 73, 0.77);
  filter: blur(143.9px); }



a {
    text-decoration: none; }

svg,
img {
    vertical-align: middle; }

.desktop {
    &-hide {
        @include d {
            display: none !important; } }
    &-show {
        display: none !important;
        @include d {
            display: block !important; } }
    &-text-right {
        @include d {
            text-align: right; } } }

.tablet {
    &-hide {
        @include t {
            display: none !important; } }
    &-show {
        display: none !important;
        @include t {
            display: block !important; } } }

.mobile {
    &-hide {
        @include m {
            display: none !important; } }
    &-show {
        display: none !important;
        @include m {
            display: block !important; } } }


h2.slim {
    font-weight: 100; }
