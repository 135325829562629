[class^="input"] {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding: 0 24px;
  background: transparent;
  border: 1px solid #4F4F4F;
  border-radius: 6px;
  width: 400px;
  text-align: left;
  color: $white;
  transition: all .2s;
  font-size: 14px;
  &:hover {}
  &:focus {
    border: 1px solid $n8; }
  &.disabled {
    opacity: .5;
    pointer-events: none; }
  svg {
    fill: $n8;
    transition: all .2s; } }

.input-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding: 0 24px;
  background: transparent;
  border: 1px solid #4F4F4F;
  border-radius: 6px;
  width: 300px;
  text-align: left;
  color: $white;
  transition: all .2s;
  font-size: 14px;
  input {
      border: none; }
  .button {
      width: auto;
      color: #CAE8EA;
      background: transparent;
      min-width: 110px;
      font-size: 13px;
      font-weight: 400; }
  &:hover {}
  &:focus {
    background: $n8;
    border: 1px solid $n8; }
  &.disabled {
    opacity: .5;
    pointer-events: none; }
  svg {
    fill: $white;
    transition: all .2s; } }
