.select {
    padding: 10px;
    background-color: $secondary;
    border: 0.5px solid #4F4F4F;
    box-shadow: 0px 9px 25.8px 0px #00000026;
    cursor: pointer;
    min-width: 250px;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }


.selectMenuItem {
    padding: 5px;
    color: $white;
    @include saira;
    font-size: 14px;
    font-weight: 400;
    min-width: 200px; }


