.sliderLinesContainer {
          display: flex;
          justify-content: space-between; }

.sliderLine {
      background-color: #FFFFFF;
      opacity: 0.4;
      height: 8px;
      width: 1.5px; }

.sliderOptionsContainer {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          margin-top: 10px; }

.sliderOption {
      color: #FFFFFF;
      font-weight: 500;
      padding: 8px;
      min-width: 70px;
      text-align: center;
      border-radius: 4px;
      cursor: pointer; }

.sliderOptionActive {
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) -251.02%, rgba(153, 153, 153, 0) 100%);
      border: 0.5px solid;
      border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.35) -102.27%, rgba(153, 153, 153, 0) 108.93%);
      font-weight: 600; }


